
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
































.reporting-navigation-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: $--clb-color-primary__white;

  .reporting-navigation-breadcrumb {
    margin-bottom: $--clb-space-5;
  }

  &__left {
    display: flex;
    align-items: flex-end;
    position: relative;
    width: 100%;
    white-space: nowrap;
  }

  &__info {
    width: 100%;
  }
}

@media (min-width: $--sm) {
  .reporting-navigation-header {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &__left {
      flex: 2;
      overflow: hidden;
    }
  }
}
