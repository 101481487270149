
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        







































.period-type-filter {
  display: flex;
  justify-content: space-between;

  .el-radio-group {
    width: 100%;
  }

  .el-radio-button {
    box-shadow: none !important;
    min-width: 80px;
  }

  .el-radio-button__inner {
    background: #fff;
    transition: background 0.2s ease-in;
    font-size: $--clb-font-size-sm;
    width: 100%;
    text-align: center;
    padding: 9px;
    height: 40px;
    line-height: 22px;
  }

  .el-radio-button__orig-radio:checked,
  .el-radio-button__orig-radio:hover {
    + .el-radio-button__inner {
      color: $--jb-dark-primary-color;
      border-color: $--jb-dark-primary-color;
      box-shadow: -1px 0 0 0 $--jb-dark-primary-color;
    }
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: $--color-light;
  }
}
