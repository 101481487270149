
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        







































































.filters-summary {
  font-size: $--clb-font-size-xs;

  &__item {
    white-space: nowrap;
    max-width: 150px;
    text-overflow: ellipsis;
    padding-right: $--clb-space-2;

    strong {
      color: $--clb-color__headings;
      font-weight: 600;
    }
  }
}
