
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






















































































































.campaign-affiliate-reporting-daily {
  margin-top: -$--clb-space-2;

  h3 {
    margin: 0;
  }

  h5 {
    margin: $--clb-layout-1 0 $--clb-space-2 0;
    font-size: $--clb-font-size-base;
  }

  .filters-summary {
    padding-top: $--clb-space-1;
  }

  .affiliate-reporting-body {
    padding: 0 $--clb-mobile-padding;

    .daily-breakdown-header {
      .daily-breakdown-title {
        margin-top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__group {
          display: flex;
          flex-direction: column;
          flex: 7;
        }

        &__btn-group {
          display: flex;
          flex: 3;
          justify-content: flex-end;
        }

        &__btn-group-back {
          background: $--clb-color-secondary__dark;
          height: 40px;
          margin-left: $--clb-layout-1 - 1;
        }
      }
    }

    .campaign-affiliate-reporting-metrics__filters {
      margin-top: $--clb-layout-2;
    }

    .breakdown-items {
      margin-top: $--clb-space-3;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .item {
        margin: 0 auto 0 0;

        .item-title {
          color: gray;
          padding: 0 $--clb-layout-4 0 0;
          margin: 0 0 $--clb-space-1 0;
        }

        .item-content {
          font-weight: $--clb-font-weight__semi-bold;
        }
      }
    }
  }

  .daily-breakdown-reports {
    margin-top: $--clb-space-5;
  }
}

@media (min-width: $--sm) {
  .affiliate-reporting-body {
    padding: 0 $--clb-layout-2;
  }
}
