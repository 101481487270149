
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        


























.campaign-navigation-breadcrumb {
  overflow: hidden;
  min-height: 34px;
}

.campaign-navigation-breadcrumb__item {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}
